<template>
  <div>
    <loading v-if="isLoading" />
    <b-card>
      <b-row>
        <b-col md="3">
          <b-form-group label="Month">
            <model-select
              v-model="filterForm.month"
              :options="dropdown_month"
              placeholder="-- Please select --"
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group label="Year">
            <model-select
              v-model="filterForm.year"
              :options="dropdown_year"
              placeholder="-- Please select --"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="3">
          <b-button
            type="button"
            size="sm"
            variant="primary"
            @click="getData"
          >
            <i class="fa fa-download" /> Filter
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <vue-apex-charts
        v-if="!isLoading"
        type="bar"
        height="350"
        :options="barOption"
        :series="barSeries"
      />
      <loading v-if="isLoading" />
      <b-button
        id="trigger-modal-status"
        style="display:none"
        @click="previewStatus"
      />
    </b-card>

    <b-row>
      <b-col lg="6">
        <b-card v-if="showPie == true">
          <vue-apex-charts
            v-if="!isLoadingModal"
            type="pie"
            height="350px"
            :options="pieOption"
            :series="pieSeries"
          />
          <loading v-if="isLoadingModal" />
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import axios from 'axios'
import Loading from '@core/loading/Loading.vue'
import VueApexCharts from 'vue-apexcharts'
import interfaces from '@/templates/_interfaces.js'

const SUPER_ADMIN_LIST = ['NCT1509059', 'NCT2406030']

export default {
  name: 'Dashboard',
  components: {
    Loading,
    VueApexCharts,
  },
  data() {
    return {
      data: [],
      dataMemo: [],
      dataChangeRequest: [],
      dataBugs: [],
      dropdown_year: [],
      dropdown_month: [],
      // Bar chart config
      barOption: {},
      barSeries: [],
      // pie chart config
      pieOption: {},
      pieSeries: [],
      filterForm: interfaces.filter_chart,
      isLoading: false,
      isLoadingModal: false,
      isManager: false,
      showPie: false,
      isAdmin: localStorage.getItem('admin'),
      nik: localStorage.getItem('nik'),
      picked_app: '',
    }
  },
  mounted() {
    this.isManager = SUPER_ADMIN_LIST.includes(this.nik)

    this.init()
  },
  methods: {
    init() {
      this.getDropdown()
      const current = new Date()
      this.filterForm.month = current.getMonth() + 1
      this.filterForm.year = current.getFullYear()
      this.getData()
    },
    close_modal_app() {
      this.$refs.previewModalApp.hide()
    },
    getDropdown() {
      this.isLoading = true
      axios.get('/dropdown/yearMonth').then(response => {
        this.dropdown_month = response.data.dropdown_month_list
        this.dropdown_year = response.data.dropdown_year_list
        this.filterForm.month = response.data.month_now
        this.filterForm.year = response.data.year_now
      })
        .catch(err => {
          // console.log(err)
        }).finally(() => {
          this.isLoading = false
        })
    },
    getData() {
      this.isLoading = true
      this.showPie = false
      axios.post('/request/chart_dashboard', this.filterForm).then(response => {
        // chart option apexchart
        this.barOption = {
          chart: {
            id: 'chart-total-app',
            events: {
              dataPointSelection(event, chartContext, config) {
                const status_selected = chartContext.titleSubtitle.w.globals.labels[config.dataPointIndex].split(' ')
                document.getElementById('trigger-modal-status').value = status_selected[0]
                document.getElementById('trigger-modal-status').click()
              },
            },
          },
          fill: {
            colors: ['#7417ff'],
          },
          dataLabels: {
            enabled: false,
          },
          // stroke: {
          //   show: true,
          //   width: 1,
          //   colors: ['#fff']
          // },
          title: {
            text: response.data.label_chart_bar,
            align: 'left',
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'],
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: response.data.bar_chart_label, // response api buat label nya taro disini
          },
          tooltip: {
            x: {
              show: true,
              formatter(xaxis) {
                return xaxis
              },
            },
            y: {
              title: {
                formatter() {
                  return 'Total Application Submited'
                },
              },
            },
          },
        }
        this.barSeries = [{
          data: response.data.bar_chart_value, // response api buat data nya taro disini
        }]
      }).catch(err => {
        // console.log(err)
      }).finally(() => {
        this.isLoading = false
      })
    },
    previewStatus() {
      this.showPie = false
      this.filterForm.sts = document.getElementById('trigger-modal-status').value
      this.picked_app = this.filterForm.sts
      this.isLoadingModal = true
      axios.post('/request/chart_dashboard_detail', this.filterForm).then(response => {
        this.pieOption = {
          chart: {
            width: 380,
            type: 'pie',
          },
          title: {
            text: response.data.label_chart_pie,
            align: 'left',
          },
          colors: response.data.random_color,
          labels: response.data.pie_chart_label,
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'top',
              },
            },
          }],
        },
        this.pieSeries = response.data.pie_chart_value
        if (response.data.pie_chart_label.length > 0) {
          this.showPie = true
        }
      }).catch(error => {

      }).finally(() => {
        // dismiss loading
        this.isLoadingModal = false
      })
    },
  },
}
</script>
